<!-- eslint-disable -->
<template>
	<header>
		<!-- <h1>{{ headingTxt }}</h1> -->
		<router-link to="/">
			<img v-if="this.noLogo.some(routeName => this.currentRouteName.includes(routeName)) ? false : true" src="../assets/logo-notext.svg" alt="PeakFlights logo" srcset="">
		</router-link>
		<h2 v-if="displayName" id="userWelcome">Welcome, {{ displayName }}</h2>
		<nav>
			<!-- <ul>
				<li :class="this.currentRouteName == 'Home' ? 'linkHighlight' : ''"><router-link to="/"><i class="fas fa-home"></i>Home</router-link></li>
				<li :class="this.currentRouteName == 'About' ? 'linkHighlight' : ''"><router-link to="/about"><i class="fas fa-info-circle"></i>About</router-link></li>
			</ul> -->
			<ul v-if="user">
				<li :class="this.currentRouteName == 'Profile' ? 'linkHighlight' : ''"><router-link to="/profile"><i class="fas fa-user"></i>Profile</router-link></li>
				<li :class="this.currentRouteName == 'Dashboard' ? 'linkHighlight' : ''"><router-link to="/dashboard"><i class="fas fa-columns"></i>Dashboard</router-link></li>
				<li :class="this.currentRouteName == 'Flights' ? 'linkHighlight' : ''"><router-link to="/flights"><i class="fas fa-plane"></i>Flights</router-link></li>
				<li :class="this.currentRouteName == 'Analysis' ? 'linkHighlight' : ''"><router-link to="analysis"><i class="far fa-chart-bar"></i>Analysis</router-link></li>
				<li id="logoutBtn" @click="logOut"><i class="fas fa-sign-out-alt"></i>Logout</li>
			</ul>
			<div v-if="!user">
				<router-link to="/signup">
					<AuthBtn btnText="Sign Up" />
				</router-link>
				<router-link to="/login">
					<AuthBtn btnText="Login" />
				</router-link>
			</div>
		</nav>
	</header>
</template>

<script>
/* eslint-disable */
import AuthBtn from '../components/AuthBtn.vue'

import { auth } from '../firebase/config.js'

import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed } from '@vue/reactivity';

export default {
	name: 'Navbar',
	components: {
		AuthBtn
	},
	props: {
		headingTxt: {
			type: String,
			default: 'PeakFlights'
		},
	},
	data() {
		return {
			noLogo: ['Home', 'About'],
		}
	},
	setup() {
		const store = useStore()
		const router = useRouter()

		const displayName = ref('')

		displayName.value = store.state.user ? store.state.user.displayName : null

		const logOut = async () => {
			await store.dispatch('logout')
			router.push('/')
		}

		return {
			logOut,
			displayName,
			user: computed(() => store.state.user),
			authIsReady: computed(() => store.state.authIsReady)
		}
	},
	computed: {
		// homePage() {
		// 	return this.$route.path === '/'
		// }
		currentRouteName() {
			return this.$route.name
		}
	},
}
</script>

<style scoped>

header nav ul {
    display: flex;
    list-style: none;
}

header nav ul li, header nav ul li a {
    color:white;
    text-decoration: none;
    padding: 0.1em;
    transition: .4s;
}

header nav ul li:hover {
    color: white;
    background-color: var(--btnHover);
    cursor: pointer;
}

header nav ul li {
    background-color: var(--tertiaryBg);
    /* border: var(--btnColor) solid 1px; */
    border-radius: 0.5em;
    margin: 0.5em;
}

li a {
    margin: 0 0.5em;
}

li svg {
	margin-right: 0.5em;
}

img {
	width: 7vh;
	height: min-content;
	margin: 5px;
}
header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1rem;
	margin-bottom: 0em;
	height: 6vh;
}

header div {
	display: flex;
	justify-content: center;
	align-items: center;
}

nav {
	display: flex;
}

ul li {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 2rem;
	width: max-content;
}


#logoutBtn i {
    margin-left: 0.5em;
}

#logoutBtn {
	width: 6.5rem;
	cursor: pointer;
}

.linkHighlight {
	background-color: var(--btnHover);
}

header {
    background: var(--secondaryBg);
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    margin: 0px;
    margin-bottom: 1em;
    display: flex;
    padding: 0px;
}
/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait and Landscape */
@media only screen and (max-width: 1250px) {
    header {
		font-size: 1.1rem;
		height: 5vh;
	}
	h1 {
		font-size: 1.35rem;
	}
}
</style>