<!-- eslint-disable -->
<template>
	<footer>
		<ul>
			<li>
				Help
			</li>
			<li>
				Contact
			</li>
			<li>
				Copyright &copy; {{ year }}
			</li>
		</ul>
	</footer>
</template>

<script>
/* eslint-disable */
export default {
	name: 'Footer',
	props: {
		year: {
			type: Number,
			default: new Date().getFullYear()
		}
	}
}

</script>

<style scoped>
footer {
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	background-color: var(--secondaryBg);
	color: white;
	text-align: center;
	height: 2vh;
}

ul {
	margin: 0em;
	display: flex;
	list-style: none;
	justify-content: center;
	padding: 0.25em;
}

li {
	margin: 0em 2em;
	font-size: 0.7em;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
	footer {
		font-size: 0.6em;
	}
	ul {
		justify-content: start;
	}
}
</style>