<!-- eslint-disable -->
<template>
	<button id="AuthBtn">{{ btnText }}</button>
</template>

<script>
/* eslint-disable */
export default {
	name: 'AuthBtn',
	props: {
		btnText: {
			type: String,
			default: 'Login'
		}
	},
}
</script>


<style scoped>
button {
    width: 150px;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--btnColor);
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: 0.3s;
    height: 4vh;
}

button:hover {
    background-color: var(--btnHover);
	color: white;
}
</style>