<!-- eslint-disable prettier/prettier !-->
<template>
    <li>
        <h3>{{ title }}: </h3>
        <div v-show="!showEdit">
            <h3>{{ userData }}</h3>
            <div v-if="allowEdit" @click="editInfo" >
                <i class="far fa-edit editBtn allowEvent"></i>
            </div>
        </div>
        <div v-show="showEdit">
            <input type="text" class="editInput" :value="userData">
            <div @click="saveInfo">
                <i class="far fa-save saveBtn allowEvent"></i>
            </div>
        </div>
    </li>
</template>

<script>
/* eslint-disable */

export default {
    name: "ProfileInfo",
    props: {
        userData: {
            type: String,
            required: true,
            default: ''
        },
        title: {
            type: String,
            required: true,
            default: ''
        },
        allowEdit: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showEdit: false,
        }
    },
    methods: {
        editInfo() {
            this.showEdit = !this.showEdit
        },
        saveInfo(event) {
            this.showEdit = !this.showEdit
            // console.log(event.target.parentElement.parentElement.children[0].value) // gets from save icon to input
            this.$emit('edited', event.target.parentElement.parentElement.children[0].value)
        }
    }
}
</script>

<style scoped>
li {
    font-size: xx-large;
    display: flex;
}

li div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

h3 {
    margin: 0 10px;
}

.editBtn {
    transition: 0.1s;
    pointer-events: all;
}

.editBtn:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.editInput {
    border: none;
    border-bottom: 1px solid var(--border);
    background-color: var(--tertiaryBg);
    color: white;
    font-size: xx-large;
}

.saveBtn:hover {
    cursor: pointer;
    transform: scale(1.2);
}

.saveBtn {
    transition: 0.1s;
}
</style>