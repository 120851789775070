<!-- eslint-disable prettier/prettier !-->
<template>
    <section id="breakdown">
        <summary :class="wideList ? 'wideBox' : ''" id="good" v-if="this.breakdown.good">
            <h3>&#10004;</h3>
            <div :class="wideList ? 'wideList' : ''">
                <p v-for="item in this.breakdown.good" :key="item.notes">
                    {{ item.notes }}
                </p>
            </div>
        </summary>
        <summary :class="wideList ? 'wideBox' : ''" id="bad" v-if="this.breakdown.bad">
            <h3>&#9888;</h3>
            <div :class="wideList ? 'wideList' : ''">
                <p v-for="item in this.breakdown.bad" :key="item.notes">
                    {{ item.notes }}
                </p>
            </div>
        </summary>
    </section>
</template>
<script>
/* eslint-disable */

export default {
    name: "BriefBreakdown",
    props: {
        breakdown: {
            type: Object,
            default: {
                good: [],
                bad: [],
            }
        },
        wideList: {
            type: Boolean,
            default: false,
        }
    }
}
</script>

<style scoped>
/* eslint-disable */
#breakdown {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1vh;
}
summary {
    display: flex;
    text-align: center;
    padding: 0.25vh;
    border-radius: 1rem;
    width: 15rem;
}

summary h3 {
    margin: 0px;
}

#good {
    background-color: var(--secondaryBg);
    color: #1fff4b;
    -webkit-box-shadow: var(--boxShadow);
    box-shadow: var(--boxShadow);
}

#bad {
    background-color: var(--secondaryBg);
    color: #ff1f1f;
    -webkit-box-shadow: var(--boxShadow);
    box-shadow: var(--boxShadow);
}

div {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.wideBox {
    width: 30rem;
}
summary h3 {
    font-size: 2.25rem;
    margin: 0px 0px 0px 0.5em;
}

p {
    font-size: 1.25rem;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) {
    summary {
        width: 12rem;
        height: 3vh;
        justify-content: center;
        align-items: center;
    }
    summary h3 {
        font-size: 2.5rem;
        margin: 0px;
    }
    p {
        font-size: 14px;
    }
}

@media screen and (max-width: 900px) {
    summary {
        width: 10rem;
        height: 3vh;
        justify-content: center;
        align-items: center;
    }
    summary h3 {
        font-size: 2rem;
        margin: 0px;
    }
    p {
        font-size: 12px;
    }
}
</style>