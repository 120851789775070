/* eslint-disable */
import { createRouter, createWebHistory } from "vue-router";
import About from "../views/About.vue";
import Home from "../views/Home.vue";
import Login from "../views/Login.vue";
import Signup from "../views/Signup.vue";
import Dashboard from "../views/Dashboard.vue";
import Profile from "../views/Profile.vue";
import Flights from "../views/Flights.vue";
import Analysis from "../views/Analysis.vue";

import Admin from "../views/Admin.vue";

import { auth } from "../firebase/config.js";
import { onAuthStateChanged } from "firebase/auth";

const routes = [
	// {
	// 	path: "/about",
	// 	name: "About",
	// 	component: About,
	// 	meta: {
	// 		title: 'About',
	// 		requiresAuth: false,
	// 		requiresAdmin: false,
	// 	}
	// },
	{
		path: "/",
		name: "Home",
		component: Home,
		meta: {
			title: 'Home',
			requiresAuth: false,
			requiresAdmin: false,
		}
	},
	// {
	// 	path: "/login",
	// 	name: "Login",
	// 	component: Login,
	// 	meta: {
	// 		title: 'Login',
	// 		requiresAuth: false,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/signup",
	// 	name: "Signup",
	// 	component: Signup,
	// 	meta: {
	// 		title: "Signup",
	// 		requiresAuth: false,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/dashboard",
	// 	name: "Dashboard",
	// 	component: Dashboard,
	// 	meta: {
	// 		title: "Dashboard",
	// 		requiresAuth: true,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/profile",
	// 	name: "Profile",
	// 	component: Profile,
	// 	meta: {
	// 		title: "Profile",
	// 		requiresAuth: true,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/flights",
	// 	name: "Flights",
	// 	component: Flights,
	// 	meta: {
	// 		title: "Flights",
	// 		requiresAuth: true,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/analysis",
	// 	name: "Analysis",
	// 	component: Analysis,
	// 	meta: {
	// 		title: "Analysis",
	// 		requiresAuth: true,
	// 		requiresAdmin: false,
	// 	}
	// },
	// {
	// 	path: "/admin",
	// 	name: "Admin",
	// 	component: Admin,
	// 	meta: {
	// 		title: "Admin",
	// 		requiresAuth: true,
	// 		requiresAdmin: true,
	// 	}
	// }
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

// import { store } from "../store/index.js";

// router.beforeEach(async (to, from, next) => { //* use once admin is implemented
// 	const usr = await user();
// 	if (usr && (to.path === "/login" || to.path === "/signup")) { // if user is already logged in and attempts to go to login or signup page
// 		next('/dashboard');
// 	} else if ( usr && to.meta.requiresAuth && !to.meta.requiresAdmin ) { // if user is logged in and attempts to go to a page that requires authentication
// 		next();
// 	} else if ( usr && to.meta.requiresAdmin ) { // if user is logged in and attempts to go to a page that requires admin authentication
// 		if ( usr.admin ) {
// 			next();
// 		} else {
// 			next('/');
// 		}
// 	} else if ( !usr && to.meta.requiresAuth ) { // if user is not logged in and attempts to go to a page that requires authentication
// 		next('/login');
// 	} else {
// 		next();
// 	}
// });

router.beforeEach(async (to, from, next) => {
	const usr = await user();
	// document.title = `${to.meta.title} | PeakFlights`;
	if (usr && (to.path === "/login" || to.path === "/signup")) { // if user is already logged in and attempts to go to login or signup page
		next('/dashboard');
	} else if ( usr && to.meta.requiresAuth && !to.meta.requiresAdmin ) { // if user is logged in and attempts to go to a page that requires authentication
		next();
	} else if ( usr && to.meta.requiresAdmin ) { // if user is logged in and attempts to go to a page that requires admin authentication
		next();
		// if ( usr.admin ) {
		// 	next();
		// } else {
		// 	next('/');
		// }
	} else if ( !usr && to.meta.requiresAuth ) { // if user is not logged in and attempts to go to a page that requires authentication
		next('/login');
	} else {
		next();
	}
});

router.afterEach((to, from) => {
	if (to.meta.title) {
		document.title = `${to.meta.title} | PeakFlights`;
	}
});


const user = () => {
	return new Promise((resolve, reject) => {
		const unSub = onAuthStateChanged(auth, user => {
			unSub();
			// console.log(user);
			resolve(user);
		}, reject);
	});
};
export default router;
