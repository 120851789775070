<!-- eslint-disable -->
<template>
    <header>
        <nav>
			<ul @mouseover="this.currentRouteName == 'Home' ? this.active = false : null">
				<li @mouseleave="this.active = false"> <!-- removes active effect that prevented button from starting collapsed on initial load -->
                    <div class="nav-primary" :class="this.currentRouteName == 'Home' && this.active ? 'openText' : ''">
                        <router-link to="/">
                            <i class="fas fa-home"></i>
                            <h3 class="nav-secondary">Home</h3>
                        </router-link>
                    </div>
                </li>
				<li @mouseleave="this.active = false">
                    <div class="nav-primary" :class="this.currentRouteName == 'About' && this.active ? 'openText' : ''">
                        <router-link to="/about">
                            <i class="fas fa-info-circle"></i>
                            <h3 class="nav-secondary">About</h3>
                        </router-link>
                    </div>
                </li>
                <li @mouseleave="this.active = false">
                    <div class="nav-primary" :class="this.currentRouteName == 'Login' && this.active ? 'openText' : ''">
                        <router-link to="/login">
                            <i class="fa-solid fa-right-to-bracket"></i>
                            <h3 class="nav-secondary">Login</h3>
                        </router-link>
                    </div>
                </li>
                <li @mouseleave="this.active = false">
                    <div class="nav-primary" :class="this.currentRouteName == 'Signup' && this.active ? 'openText' : ''">
                        <router-link to="/signup">
                            <i class="fa-solid fa-user-plus"></i>
                            <h3 class="nav-secondary">Signup</h3>
                        </router-link>
                    </div>
                </li>
			</ul>
        </nav>
    </header>
</template>

<script>
/* eslint-disable */
import AuthBtn from '../components/AuthBtn.vue'

export default {
    name: 'HomeNav',

    components: {
        AuthBtn
    },
    data() {
        return {
            active: true,
            isMouseOver: true,
        }
    },
	computed: {
		currentRouteName() {
			return this.$route.name
		},
	},
}
</script>

<style scoped>
* {
    box-sizing: border-box;
}

header nav ul {
    display: flex;
    list-style: none;
    position: relative;
    top: 0;
    padding: 0;
}

header nav ul li, header nav ul li a {
    color: var(--btnLight);
    transition: .4s;
}

header nav ul li:hover {
    color: white;
    cursor: pointer;
}

header nav ul li {
    background-color: var(--background-color);
    /* border-radius: 0.5em; */
    margin: 0 1em;
    display: flex;
    width: max-content;
}

nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: min-content;
    text-decoration: none;
}

h3 {
    height: min-content;
    margin: 0 0 0 0.5em;
}

i {
    margin-right: 0.5em;
}

.nav-secondary {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 0%;
    overflow: hidden;
    visibility: hidden;
}

.nav-primary:hover .nav-secondary {
    width: 100%;
    color: var(--btnLightHover);
    box-shadow: var(--boxShadow);
    visibility: visible;
}

.nav-primary:hover i {
    color: var(--btnLightHover);
}

.openText .nav-secondary {
    -webkit-transition: 0s;
    transition: 0s;
    width: 100%;
    color: var(--btnLightHover);
    box-shadow: var(--boxShadow);
    /* transition-delay: .5s; */
}

.nav-primary:not(:hover) .nav-secondary {
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    width: 0%;
    color: var(--btnLight);
    box-shadow: var(--boxShadow);
}
</style>