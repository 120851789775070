/* eslint-disable */
import { initializeApp } from "firebase/app";
// eslint-disable-next-line no-unused-vars
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

//! Firebase Stuff
// Your web app's Firebase configuration
//* Old config
// const firebaseConfig = {
// 	apiKey: "AIzaSyAn3wQjnlvjl7qgqFa-tv9d5W3OffF0djQ",
// 	authDomain: "flt-trainer-mvp-test.firebaseapp.com",
// 	projectId: "flt-trainer-mvp-test",
// 	storageBucket: "flt-trainer-mvp-test.appspot.com",
// 	messagingSenderId: "515668741605",
// 	appId: "1:515668741605:web:766eeb55b58352e24eb30c",
// 	measurementId: "G-MJ24CH1H9D"
// };

//* Production Config
const firebaseConfig = {
	apiKey: "AIzaSyAlWKMQoSC8e4bIjQQVtbTRtAIT9-la8Kk",
	authDomain: "peakflights-production.firebaseapp.com",
	projectId: "peakflights-production",
	storageBucket: "peakflights-production.appspot.com",
	messagingSenderId: "530221712407",
	appId: "1:530221712407:web:e5416690a2dcff83188852",
	measurementId: "G-RJTP2FVFX8"
};

//* Staging Config
// const firebaseConfig = {
// 	apiKey: "AIzaSyDbOLFMJCcW3YzryxpmmXqmyhiaO7NnFcw",
// 	authDomain: "peakflights-staging.firebaseapp.com",
// 	projectId: "peakflights-staging",
// 	storageBucket: "peakflights-staging.appspot.com",
// 	messagingSenderId: "519731941000",
// 	appId: "1:519731941000:web:40958e2820a32d78cf993d",
// 	measurementId: "G-Z2GQEDRFP4"
// };

//* Testing Config
// const firebaseConfig = {
//   apiKey: "AIzaSyBBQR0lRhuTeF9iknUAF_JXslErJIdPh_I",
//   authDomain: "peakflights-testing.firebaseapp.com",
//   projectId: "peakflights-testing",
//   storageBucket: "peakflights-testing.appspot.com",
//   messagingSenderId: "732414044926",
//   appId: "1:732414044926:web:6607a353a95e14da3f53af",
//   measurementId: "G-8JD0S5TPBL"
// };


//* Development Config
// const firebaseConfig = {
// 	apiKey: "AIzaSyDijXnYi10rrU-ji7iK-wG35owDXxgiSAs",
// 	authDomain: "peakflights-dev.firebaseapp.com",
// 	projectId: "peakflights-dev",
// 	storageBucket: "peakflights-dev.appspot.com",
// 	messagingSenderId: "547100450625",
// 	appId: "1:547100450625:web:b04831799f0cca954acbc0",
// 	measurementId: "G-LK2WSQEJ6L"
// };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(firebaseApp);

// Export Modules
export const auth = getAuth(firebaseApp);
export const db = getFirestore(firebaseApp);
export const functions = getFunctions(firebaseApp);
// export const storage = getStorage(firebaseApp);

export default { auth, db };