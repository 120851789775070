<!-- eslint-disable -->
<template>
	<div id="nav">
		<!-- <HomeNav /> -->
	</div>
	<div id="center-logo">
		<img src="../assets/PeakFlights.svg" alt="">
		<h3>A next-gen debriefing tool for pilots.</h3>
		<div id="downIcon" @click="scrollToAbout">
			<i class="fa-solid fa-forward fa-bounce fa-3x" style="--fa-animation-duration: 2s;" data-fa-transform="rotate-90"></i>
		</div>
	</div>
	<!-- <AboutSection /> -->
	<!-- <div id="signupPusher" @click="scrollToBottom">
		<i class="fa-solid fa-forward fa-bounce fa-3x" style="--fa-animation-duration: 2s;" data-fa-transform="rotate-90"></i>
	</div> -->
	<section id="info">
		<summary>
			PeakFlights is currently in closed alpha development. If you'd like to hear when open testing begins, please fill out the form below.
		</summary>
	</section>
	<section id="signup">
		<form id="loginForm" @submit.prevent="addToEmailList"  v-if="buttonEnabled">
			<input type="text" name="name" placeholder="Name" v-model="name" required>
			<input type="email" name="email" placeholder="Email" v-model="email" required>
			<AuthBtn btnText="Notify Me!" />
		</form>
		<p v-if="errorMessage" id="errorMessage" :style="[redText.value ? { 'color': 'red' } : { 'color': '#24fc03' }]">{{ errorMessage }}</p>
	</section>
</template>

<script>
/* eslint-disable */
import HomeNav from '../components/HomeNav.vue'
import AuthBtn from '../components/AuthBtn.vue'

import AboutSection from './About.vue'

import { db } from "../firebase/config";
import { setDoc, doc, getDoc } from 'firebase/firestore'

import { ref } from 'vue';
import { useStore } from 'vuex';
import { computed } from '@vue/reactivity';

export default {
	name: "Home",
	components: {
		HomeNav,
		AuthBtn,
		AboutSection
	},
	methods: {
		scrollToBottom() {
			window.scrollTo({
				top: window.scrollY + window.innerHeight,
				behavior: 'smooth'
			})
		},
		scrollToAbout() {
			window.scrollTo({
				top: window.innerHeight,
				behavior: 'smooth'
			})
		},
	},
	setup() {
		const name = ref('')
		const email = ref('')
		const errorMessage = ref(null)
		const redText = ref(false);
		const buttonEnabled = ref(true)
		
		const addToEmailList = async () => {
			try {
				const waitlistRef = doc(db, "waitlist", email.value);

				const docSnap = await getDoc(waitlistRef);

				if (docSnap.data()) {
					// Username already in list
					docSnap.id == email.value ? errorMessage.value = "You're already on the waitlist!" : errorMessage.value = "That username is already taken!";
					redText.value = true;
				} else {
					const docRef = await setDoc(doc(db, "waitlist", `${email.value}`), {
						name: name.value,
						email: email.value
					});
					errorMessage.value = `Thanks for signing up, ${name.value}! We'll notify you when we're ready to go live.`
				}
				buttonEnabled.value = false;
			} catch (error) {
				redText.value = true
				let errorString
				const errorCode = error.code;
				switch (errorCode) {
					case "auth/email-already-in-use":
						errorString = "Email already in use";
						break;
					case "auth/invalid-email":
						errorString = "Invalid email";
						break;
					case "auth/operation-not-allowed":
						errorString = "Operation not allowed";
						break;
					case "auth/weak-password":
						errorString = "Weak password (Please choose a password with at least 6 characters)";
						break;
				}
				errorMessage.value = errorString;
			}
		}

		return { addToEmailList, email, name, redText, errorMessage, buttonEnabled }
	}
};
</script>

<style scoped>
/* Hide scrollbars while allowing scroll */
body::-webkit-scrollbar { width: 0 !important }
body { overflow: -moz-scrollbars-none; }
body { -ms-overflow-style: none; }

html {
  scroll-behavior: smooth;
}

#nav {
	/* transform: translateY(-100%); */
	animation: 1s ease-out 0s 1 slideInFromTop;
}

h3 {
	margin-bottom: 5em;
}

img {
	width: 45%;
	height: auto;
}

#center-logo {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2em;
	margin-bottom: 15em;

    animation: fadeInAnimation ease 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

#downIcon {
	animation: 2s ease-out 0s 1 slideInFromBottom;
}

#downIcon svg:hover {
	cursor: pointer;
}

#info {
	margin: 25em 0 2em 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#info summary {
	margin-bottom: 2em;
	font-size: 1.5em;
	width: 50%;
	text-align: center;
}
  
@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
}

@keyframes slideInFromTop {
	0% {
		transform: translateY(-100%);
	}
	50% {
		transform: translateY(-100%);
	}
	100% {
		transform: translateY(0);
	}
}

@keyframes slideInFromBottom {
	0% {
		transform: translateY(1000%);
		opacity: 0;
	}
	70% {
		transform: translateY(1000%);
		opacity: 0;
	}
	100% {
		transform: translateY(0);
		opacity: 100%;
	}
}

#signupPusher {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-top: 2.5em;
}
#signupPusher:hover {
	cursor: pointer;
}

/* Signup Form */
#signup {
	margin: 2em 0 25em 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
	width: 80%;
	margin: auto;
}

#loginForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    height: 100%;
    background-color: var(--secondaryBg);
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#loginForm input, #license {
    width: 80%;
    height: 40px;
    border: none;
    border-bottom: 1px solid var(--border);
    background-color: var(--tertiaryBg);
    color: white;
    font-size: 1.2em;
    padding: 0 10px;
    margin-bottom: 10px;
}

#loginForm input:focus {
    outline: none;
}

#loginForm button {
    width: 60%;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--btnColor);
    color: white;
    font-size: 1.2em;
    cursor: pointer;
    transition: 0.3s;
    margin-top: 0.25em;
}

#loginForm button:hover {
    background-color: var(--btnHover);
}

a {
    text-decoration: none;
    color: var(--title);
    margin-bottom: 0.5em;
}

a:hover {
    color: var(--btnHover);
}

input, select {
	display: block;
}

@media screen and (max-width: 1000px) {
	img {
		width: 80%;
	}

	#center-logo {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 2em;

		animation: none;
	}

	#loginForm { width: 80%; }
	#info { margin-top: 50vh; }
	#info summary { width: 80% }
}
</style>