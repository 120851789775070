<!-- eslint-disable -->
<template>
  <!-- <HomeNav /> -->
  <section id="analysis">
    <h1 class="alignRight">INTUITIVE VISUALIZATIONS</h1>
    <div class="details">
      <img id="vizImg" src="../assets/apprchpath.svg" alt="">
      <summary>PeakFlights breaks your data down into bite sized chunks, making it easy to understand where you went right or wrong.</summary>
    </div>
  </section>
  <section id="insights">
    <h1>ACTIONABLE INSIGHTS</h1>
    <div class="details">
      <summary class="alignRight">PeakFlights will notice trends that you or your instructor might not over larger periods of time. It will point out crucial risk factors so you can focus on correcting them.</summary>
      <img src="../assets/chart-demo1.svg" alt="">
    </div>
  </section>
  <section id="tracking">
    <h1 class="alignRight">ESSENTIAL METRICS</h1>
    <div class="details">
      <img src="../assets/chart-demo1.svg" alt="">
      <summary>PeakFlights rates your flying and shows your progression over time. Track all crucial elements, from your overall PeakFlights Rating to individual component scores.</summary>
    </div>
  </section>
</template>
<script>
/* eslint-disable */
import HomeNav from '../components/HomeNav.vue'

export default {
	name: "About",
  components: {
    HomeNav
  },
};
</script>

<style scoped>
h1 {
  font-size: 6rem;
  margin: .5em 1em;
}

section {
  height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

#analysis, #tracking {
  background-color: var(--secondaryBg);
  box-shadow: var(--boxShadow);
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details img {
  padding: 1em;
  margin: 2em;
  box-shadow: var(--boxShadow);
  /* background-color: var(--secondaryBg); */
  border-radius: 2em;
}

summary {
  font-size: 26px;
  margin: 0 10%;
}

.alignRight {
  text-align: right;
}

@media screen and (max-width: 1000px) {
  h1 {
    font-size: 4rem;
  }
}
</style>