/* eslint-disable */
import { createStore } from "vuex";

// Firebase imports
import { auth, db } from "../firebase/config";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { setDoc, doc, Timestamp, addDoc, collection } from 'firebase/firestore'

export const store = createStore({
	state: {
		user: null,
		authIsReady: false,
		currentAnalysisData: null,
	},
	mutations: {
		setUser(state, payload) {
			state.user = payload;
		},
		setAuthIsReady(state, payload) {
			state.authIsReady = payload;
		},
		setCurrentAnalysisData(state, payload) {
			state.currentAnalysisData = payload;
		}
	},
	actions: {
		async signup(context, { email, password, name, license, hours }) {
			const res = await createUserWithEmailAndPassword(auth, email, password);
			if (res) {
				await setDoc(doc(db, `users`, `${res.user.uid}`), {
					name: name,
					email: email,
					dateCreated: new Date(),
					license: license,
					hours: hours,
				}); // Sets user document in users collection
				context.commit('setUser', res.user);
				// console.log(`Signup: ${res}`);
			} else {
				throw new Error('Error creating user');
			}
		},
		async login(context, {email, password}) {
			const res = await signInWithEmailAndPassword(auth, email, password);
			// console.log(`Login: ${res.user}`);
			if (res) {
				context.commit('setUser', res.user);
			} else {
				throw new Error('Error logging in');
			}
		},
		async logout(context) {
			await signOut(auth);
			context.commit('setUser', null);
			context.commit('setCurrentAnalysisData', null);
		},
		async addAnalysis(context, {serverResponse, extraData, uid}) {
			const res = await addDoc(collection(db, `users/${uid}/flights`), {
				tailNumber: extraData.tailNumber,
				airport: extraData.airport,
				averages: extraData.flightStats,
				dataSource: extraData.dataSource,
				instructorID: extraData.instructorID,
				remarks: extraData.remarks,
				downloadURL: extraData.downloadURL,
				timestamp: Timestamp.now(),
				rating: extraData.flightStats.total.rating,
				startTime: extraData.startTime,
				endTime: extraData.endTime,
				landings: extraData.totalPatterns,
			});

			if (res) {
				context.commit('setCurrentAnalysisData', {serverRes: serverResponse, extra: extraData});
				// console.log(`Analysis added to local storage: ${res}`);
			} else {
				throw new Error('Error adding analysis');
			}
		},
		async clearAnalysis(context) {
			context.commit('setCurrentAnalysisData', null);
		}
	},
});

const unSub = onAuthStateChanged(auth, (user) => {
	store.commit('setAuthIsReady', true);
	store.commit('setUser', user);
	unSub();
});

export default store;